import {
  getUniqueImpressionsCount,
  getUniqueLocationsCount,
  groupImpressionsByMonth,
} from "./helpers";
import { supabase } from "utils/supabase";
import {
  ADMIN_USER,
  BUSINESS_USER,
  CLIENT,
  COLLECTIONS,
  FIELDS,
  PROVIDER,
} from "utils/constants";

const startOfYear = new Date(new Date().getFullYear(), 0, 1).toISOString();
const startOfNextYear = new Date(new Date().getFullYear() + 1, 0, 1).toISOString();


export const fetchPageContent = async ({ tag }) => {
  const { data, error } = await supabase
    .from(COLLECTIONS.web_content)
    .select("*")
    .eq(FIELDS.tag, tag)
    .eq(FIELDS.active, true);

  if (error) return error;
  return data[0];
};

export const fetchTestimonials = async (limit = 3) => {
  const { data, error } = await supabase
    .from(COLLECTIONS.testimonials)
    .select("*")
    .eq(FIELDS.active, true)
    .limit(+limit);

  if (error) return error;
  return data;
};

export const fetchFeaturedProviders = async (limit = 12) => {
  const { data, error } = await supabase
    .from(COLLECTIONS.profile)
    .select(
      `id, firstname, lastname, about, avatar, gender, city, state, mobile, verified, expertise, last_login, fcm_token, instagram, twitter, snapchat, tiktok, slug`
    )
    .eq(FIELDS.featured, true)
    .order(FIELDS.is_verified, { ascending: false })
    .order(FIELDS.last_login, { ascending: false })
    .limit(+limit);

  if (error) return error;
  return data;
};

export const fetchFaqs = async ({ tag }) => {
  const { data, error } = await supabase
    .from(COLLECTIONS.web_faqs)
    .select("*")
    .eq(FIELDS.active, true)
    .eq(FIELDS.tag, tag)
    .order(FIELDS.question, { ascending: false }) 

  if (error) return error;
  return data;
};

const getViewsForProvider = async (slug) => {
  try {
    const { data, error } = await supabase
      .from(COLLECTIONS.providers_page_views)
      .select("*")
      .eq(FIELDS.slug, slug)
      .gte(FIELDS.timestamp, startOfYear)
      .lte( FIELDS.timestamp, startOfNextYear);

    if (error) throw error;

    return data;
  } catch (error) {
    return [];
  }
};

export const getViewsForProviders = async () => {
  try {
    const { data, error } = await supabase
      .from(COLLECTIONS.providers_page_views)
      .select("*")
      .gte(FIELDS.timestamp, startOfYear)
      .lte( FIELDS.timestamp, startOfNextYear);

    if (error) throw error;

    return data;
  } catch (error) {
    return [];
  }
};

export const getViewsForBlog = async () => {
  try {
    const { data, error } = await supabase
      .from(COLLECTIONS.blog_page_views)
      .select("*")
      .gte(FIELDS.timestamp, startOfYear)
      .lte( FIELDS.timestamp, startOfNextYear);

    if (error) throw error;

    return data;
  } catch (error) {
    return [];
  }
};

export const getTotalInbox = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.inbox)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalUserInbox = async (userId) => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.inbox)
      .select("*", { count: "exact" })
      .eq(FIELDS.recipient, userId);

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalServices = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.services)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalProviders = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.profile)
      .select("*", { count: "exact" })
      .eq(FIELDS.account, PROVIDER);

    return count;
  } catch (error) {
    return 0;
  }
};

export const getProviders = async () => {
  try {
    const { data, error } = await supabase
      .from(COLLECTIONS.profile)
      .select("state, city, location")
      .eq(FIELDS.account, PROVIDER);

    if (error) throw error;
    return data;
  } catch (error) {
    return [];
  }
};

export const getTotalClients = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.profile)
      .select("*", { count: "exact" })
      .eq(FIELDS.account, CLIENT);

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalBusiness = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.profile)
      .select("*", { count: "exact" })
      .eq(FIELDS.account, BUSINESS_USER);

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalGallery = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.galleries)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalSupport = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.support_messages)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalBlogViews = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.blog_page_views)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const getTotalProfileViews = async () => {
  try {
    const { count } = await supabase
      .from(COLLECTIONS.providers_page_views)
      .select("*", { count: "exact" });

    return count;
  } catch (error) {
    return 0;
  }
};

export const fetchAdminDashboardData = async (user) => {
  const providersData = await getProviders();
  const totalProviders = await getTotalProviders();
  const totalInbox = await getTotalInbox();
  const totalUserInbox = await getTotalUserInbox(user.id);
  const totalServices = await getTotalServices();
  const totalClients = await getTotalClients();
  const totalBusiness = await getTotalBusiness();
  const totalGallery = await getTotalGallery();
  const totalSupport = await getTotalSupport();
  const totalBlogViews = await getTotalBlogViews();
  const totalProviderViews = await getTotalProfileViews();
  const providerViewsData = await getViewsForProvider(user.slug);
  const providersViewsData = await getViewsForProviders();
  const blogViewsData = await getViewsForBlog();

  return {
    locationData: getUniqueLocationsCount(providersData),
    totalImpression: providerViewsData.length,
    providerPageViewData: groupImpressionsByMonth(providerViewsData),
    pageViewData: groupImpressionsByMonth(providerViewsData),
    totalUniqueImpression: getUniqueImpressionsCount(providerViewsData),
    providersPageViewData: groupImpressionsByMonth(providersViewsData),
    blogPageViewData: groupImpressionsByMonth(blogViewsData),
    totalProviders,
    totalServices,
    totalInbox,
    totalUserInbox,
    totalClients,
    totalBusiness,
    totalGallery,
    totalSupport,
    totalBlogViews,
    totalProviderViews,
  };
};

export const fetchUserDashboardData = async (user) => {
  const totalUserInbox = await getTotalUserInbox(user.id);
  const providerViewsData = await getViewsForProvider(user.slug);
  const totalProviders = await getTotalProviders();
  const totalServices = await getTotalServices();

  return {
    totalImpression: providerViewsData.length,
    providerPageViewData: groupImpressionsByMonth(providerViewsData),
    totalUniqueImpression: getUniqueImpressionsCount(providerViewsData),
    totalUserInbox,
    totalProviders,
    totalServices,
  };
};

export const fetchDashboardData = async (user) => {
  switch (user.account) {
    case ADMIN_USER:
      return fetchAdminDashboardData(user);
    case PROVIDER:
      return fetchUserDashboardData(user);
    default:
      return fetchUserDashboardData(user);
  }
};
